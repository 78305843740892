
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input{
  border-radius: 5px;
  border: 0;
  padding: 5px 10px;
  margin: 10px 10px;
}

select{
  border-radius: 5px;
  border: 0;
  padding: 5px 10px;
  margin: 10px 10px;
}
.link{
  padding: 5px 10px;
  margin: 100px 10px;
  text-decoration: none;
}

.fav{
  border-radius: 45px !important;
}


.log{
  font-size: small;
  margin: 10px;
}

.justify {
  text-align: justify;
}

.text-center{
  text-align: center;
}
